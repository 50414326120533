.agora-github-pin { 
  cursor: pointer;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url("../../assets/github.png");
  background-size: 56px;
  display: block;
  width: 56px;
  height: 56px;
  margin: 20px;
  border-radius: 28px;
  right: 0px;
  top: 10px;
  z-index: 1999;
  transform: translateY(-20px);
 }

.agora-github-pin::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: inset 0 0 10px #fff06a, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff, 0 0 10px #fff06a, -6px 0 36px #f0f, 6px 0 36px #0ff;
    -webkit-animation: rotate 3s infinite linear;
    animation: rotate 3s infinite linear;
}